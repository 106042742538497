import React from 'react';
import ReactDOM from 'react-dom';
import Lottie from 'react-lottie';
import mobileAnim from './animation/mobile.json';
import gameAnim from './animation/game.json';
import hardwareAnim from './animation/hardware.json';
import securityAnim from './animation/security.json';
import qaAnim from './animation/qa.json';
import mobileHover from './animation/mobileHover.json';
import hardwareHover from './animation/hardwareHover.json';
import consultancyHover from './animation/cosultancyHover.json';
import gameHover from './animation/gameHover.json';
import qaHover from './animation/qaHover.json';
import securityHover from './animation/securityHover.json';
import consultancyAnim from './animation/consultancy.json';
import config from '../../config.json';
import { gsap } from "gsap";
import './our.services.scss';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

class OurServices extends React.Component {

   componentDidMount() {
      window.addEventListener('scroll', this.handleScroll);
   };

   componentWillUnmount() {
      window.removeEventListener('scroll', this.handleScroll);
   };

   constructor(props) {
      super(props);
      this.screenListener = 0;
      this.state = { mobConfig: false, mobAmIsPaused: true, mobAnimLoop: false };
      this.state = { gameConfig: false, gameAmIsPaused: false, gameAnimLoop: false };
      this.state = { hwConfig: false, hwAmIsPaused: false, hwAnimLoop: false };
      this.state = { secConfig: false, secAmIsPaused: false, secAnimLoop: false };
      this.state = { qaConfig: false, qaAmIsPaused: false, qaAnimLoop: false };
      this.state = { consConfig: false, consAmIsPaused: false, consAnimLoop: false };
      this.handleScroll = this.handleScroll.bind(this);
   }

   handleScroll = () => {

      ScrollTrigger.create({
         trigger: ".cs-mobile-illustration",
         start: "20px 80%",
         end: "60% 20px",
         onEnter: () => this.setState({ mobAnimLoop: true, mobAmIsPaused: false }),
         onLeave: () => this.setState({ mobAnimLoop: false, mobAmIsPaused: true }),
         onEnterBack: () => this.setState({ mobAnimLoop: true, mobAmIsPaused: false }),
         onLeaveBack: () => this.setState({ mobAnimLoop: false, mobAmIsPaused: true }),
      });


      ScrollTrigger.create({
         trigger: ".cs-game-illustration",
         start: "20px 80%",
         end: "30% 20px",
         onEnter: () => this.setState({ gameAnimLoop: true, gameAmIsPaused: false },),
         onLeave: () => this.setState({ gameAnimLoop: false, gameAmIsPaused: true }),
         onEnterBack: () => this.setState({ gameAnimLoop: true, gameAmIsPaused: false }),
         onLeaveBack: () => this.setState({ gameAnimLoop: false, gameAmIsPaused: true }),
      });

      ScrollTrigger.create({
         trigger: ".cs-quality_illustration",
         start: "20px 80%",
         end: "30% 20px",
         onEnter: () => this.setState({ qaAnimLoop: true, qaAmIsPaused: false }),
         onLeave: () => this.setState({ qaAnimLoop: false, qaAmIsPaused: true }),
         onEnterBack: () => this.setState({ qaAnimLoop: true, qaAmIsPaused: false }),
         onLeaveBack: () => this.setState({ qaAnimLoop: false, qaAmIsPaused: true }),
      });

      ScrollTrigger.create({
         trigger: ".cs-hardware_illustration",
         start: "20px 80%",
         end: "30% 20px",
         onEnter: () => this.setState({ hwAnimLoop: true, hwAmIsPaused: false }),
         onLeave: () => this.setState({ hwAnimLoop: false, hwAmIsPaused: true }),
         onEnterBack: () => this.setState({ hwAnimLoop: true, hwAmIsPaused: false }),
         onLeaveBack: () => this.setState({ hwAnimLoop: false, hwAmIsPaused: true }),
      });

      ScrollTrigger.create({
         trigger: ".cs-security_illustration",
         start: "20px 80%",
         end: "30% 20px",
         onEnter: () => this.setState({ secAnimLoop: true, secAmIsPaused: false }),
         onLeave: () => this.setState({ secAnimLoop: false, secAmIsPaused: true }),
         onEnterBack: () => this.setState({ secAnimLoop: true, secAmIsPaused: false }),
         onLeaveBack: () => this.setState({ secAnimLoop: false, secAmIsPaused: true }),
      });

      ScrollTrigger.create({
         trigger: ".cs-consultancy_illustration",
         start: "20px 80%",
         end: "30% 20px",
         onEnter: () => this.setState({ consAnimLoop: true, consAmIsPaused: false }),
         onLeave: () => this.setState({ consAnimLoop: false, consAmIsPaused: true }),
         onEnterBack: () => this.setState({ consAnimLoop: true, consAmIsPaused: false }),
         onLeaveBack: () => this.setState({ consAnimLoop: false, consAmIsPaused: true }),
      });

      if (window.scrollY >= 400 && window.scrollY <= 1000) {
         gsap.to(".cs-bg-overlay", { rotation: 0, y: 10, duration: 0.8 });
      }
      if ((window.scrollY >= 900 && window.scrollY <= 1500) && !window.innerWidth < 768) {
         gsap.to(".cs-bg-overlay", { rotation: 0, y: 620, duration: 0.8 });
      }
      if ((window.scrollY >= 1500 && window.scrollY <= 2000) && !window.innerWidth < 768) {
         gsap.to(".cs-bg-overlay", { rotation: 0, y: 1100, duration: 0.8 });
      }
      if ((window.scrollY >= 2000 && window.scrollY <= 2500) && !window.innerWidth < 768) {
         gsap.to(".cs-bg-overlay", { rotation: 0, y: 1650, duration: 0.8 });
      }
      if ((window.scrollY >= 2500 && window.scrollY <= 3000) && !window.innerWidth < 768) {
         gsap.to(".cs-bg-overlay", { rotation: 0, y: 2100, duration: 0.8 });
      }
      if ((window.scrollY >= 3000 && window.scrollY <= 3500)) {
         gsap.to(".cs-bg-overlay", { rotation: 0, y: 2600, duration: 0.8 });
      }
   }


   render() {

      const mobileAnimConfig = {
         loop: true,
         autoplay: false,
         animationData: mobileAnim,
         rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
         },
      };

      const mobileHoverConfig = {
         loop: true,
         autoplay: false,
         animationData: mobileHover,
         rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
         },
      };

      const gameAnimConfig = {
         loop: true,
         autoplay: false,
         animationData: gameAnim,
         rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
         },
      };

      const gameHoverConfig = {
         loop: true,
         autoplay: false,
         animationData: gameHover,
         rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
         },
      };

      const hardwareAnimConfig = {
         loop: true,
         autoplay: false,
         animationData: hardwareAnim,
         rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
         },
      };

      const hardwareHoverConfig = {
         loop: true,
         autoplay: false,
         animationData: hardwareHover,
         rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
         },
      };

      const securityAnimConfig = {
         loop: true,
         autoplay: false,
         animationData: securityAnim,
         rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
         },
      };

      const securityHoverConfig = {
         loop: true,
         autoplay: false,
         animationData: securityHover,
         rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
         },
      };

      const qaAnimConfig = {
         loop: true,
         autoplay: false,
         animationData: qaAnim,
         rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
         },
      };

      const qaHoverConfig = {
         loop: true,
         autoplay: false,
         animationData: qaHover,
         rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
         },
      };

      const consultancyAnimConfig = {
         loop: true,
         autoplay: false,
         animationData: consultancyAnim,
         rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
         },
      };

      const consultancyHoverConfig = {
         loop: true,
         autoplay: false,
         animationData: consultancyHover,
         rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
         },
      };

      return (
         <div id="csServices">
            <div className='cs-bg-overlay'></div>
            <div className='cs-services-row'>
               <div className='cs-services-txt cs-left-txt'>
                  <h1>{config.service_title}</h1>
                  <h3>{config.services[0].title}</h3>
                  <p>{config.services[0].content}</p>
               </div>

               <div className='cs-illustration cs-mobile-illustration'
                  onMouseEnter={() => this.setState({ mobConfig: true })}
                  onMouseLeave={() => this.setState({ mobConfig: false })}>
                  {
                     this.state.mobConfig ? <Lottie
                        options={mobileHoverConfig}
                        isStopped={false}
                        isPaused={false}
                     /> : <Lottie
                        options={mobileAnimConfig}
                        isStopped={this.state.mobAmIsPaused}
                        isPaused={this.state.mobAmIsPaused}
                     />
                  }
               </div>
            </div>

            <div className='cs-services-row'>
               <div className='cs-illustration cs-game_illustration'
                  onMouseEnter={() => this.setState({ gameConfig: true })}
                  onMouseLeave={() => this.setState({ gameConfig: false })}>
                  {
                     this.state.gameConfig ? <Lottie
                        options={gameHoverConfig}
                        isStopped={false}
                        isPaused={false}
                     /> : <Lottie
                        options={gameAnimConfig}
                        isStopped={this.state.gameAmIsPaused}
                        isPaused={this.state.gameAmIsPaused}
                     />
                  }
               </div>
               <div className='cs-services-txt cs-right-txt'>
                  <h3>{config.services[1].title}</h3>
                  <p>{config.services[1].content}</p>
               </div>
            </div>

            <div className='cs-services-row'>
               <div className='cs-services-txt cs-left-txt cs-right-align'>
                  <h3>{config.services[2].title}</h3>
                  <p>{config.services[2].content}</p>
               </div>
               <div className='cs-illustration cs-quality_illustration'
                  onMouseEnter={() => this.setState({ qaConfig: true })}
                  onMouseLeave={() => this.setState({ qaConfig: false })}>
                  {
                     this.state.qaConfig ? <Lottie
                        options={qaHoverConfig}
                        isStopped={false}
                        isPaused={false}
                     /> : <Lottie
                        options={qaAnimConfig}
                        isStopped={this.state.qaAmIsPaused}
                        isPaused={this.state.qaAmIsPaused}
                     />
                  }
               </div>

            </div>

            <div className='cs-services-row'>
               <div className='cs-illustration cs-consultancy_illustration'
                  onMouseEnter={() => this.setState({ consConfig: true })}
                  onMouseLeave={() => this.setState({ consConfig: false })}>
                  {
                     this.state.consConfig ? <Lottie
                        options={consultancyHoverConfig}
                        isStopped={false}
                        isPaused={false}
                     /> : <Lottie
                        options={consultancyAnimConfig}
                        isStopped={this.state.consAmIsPaused}
                        isPaused={this.state.consAmIsPaused}
                     />
                  }
               </div>
               <div className='cs-services-txt cs-right-txt'>
                  <h3>{config.services[3].title}</h3>
                  <p>{config.services[3].content}</p>
               </div>
            </div>

            <div className='cs-services-row'>
               <div className='cs-services-txt cs-left-txt cs-right-align'>
                  <h3>{config.services[4].title}</h3>
                  <p>{config.services[4].content}</p>
               </div>
               <div className='cs-illustration cs-hardware_illustration'
                  onMouseEnter={() => this.setState({ hwConfig: true })}
                  onMouseLeave={() => this.setState({ hwConfig: false })}>
                  {
                     this.state.hwConfig ? <Lottie
                        options={hardwareHoverConfig}
                        isStopped={false}
                        isPaused={false}
                     /> : <Lottie
                        options={hardwareAnimConfig}
                        isStopped={this.state.hwAmIsPaused}
                        isPaused={this.state.hwAmIsPaused}
                     />
                  }
               </div>
            </div>

            <div className='cs-services-row'>
               <div className='cs-illustration cs-security_illustration'
                  onMouseEnter={() => this.setState({ secConfig: true })}
                  onMouseLeave={() => this.setState({ secConfig: false })}>

                  {
                     this.state.secConfig ? <Lottie
                        options={securityHoverConfig}
                        isStopped={false}
                        isPaused={false}
                     /> : <Lottie
                        options={securityAnimConfig}
                        isStopped={this.state.secAmIsPaused}
                        isPaused={this.state.secAmIsPaused}
                     />
                  }
               </div>
               <div className='cs-services-txt cs-right-txt'>
                  <h3>{config.services[5].title}</h3>
                  <p>{config.services[5].content}</p>
               </div>
            </div>
         </div>
      );
   }
}
export default OurServices;