import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import './contact.scss';
function Contact() {     
   const [state, setState] = useState({
      fname: "",
      lname: "",
      email: "",
      message: "",
      mailLink: "mailto:admin@cloudsquads.in?subject=Service Enquiry&body= "
   }) 
   const onFormChange = event => {
      const value = event.target.value;
      let obj = Object.assign({}, state);
      obj[event.target.name] = value;
      setState(obj);
    }

    const submitForm = event => {
      event.preventDefault();
      let mLink = state.mailLink;
      window.location.href = mLink + "Hello, I am " + state.fname + " " + state.lname + ". Please contact me through this email "+state.email + ". Note: "+ state.message;
    }
       return ( 
          <div id="csContact">
             <div className="cs-contact-cont">
               <div className="cs-contact-col" >
                  <h2>NEED TO TALK?</h2>
                  <div className='cs-cnames'>
                     <input placeholder='First name' name='fname' value={state.fname} onChange={onFormChange}></input>
                     <input placeholder='Last name' name='lname' value={state.lname} onChange={onFormChange}></input>
                  </div>
                  <input placeholder='Email address' name='email' value={state.email} onChange={onFormChange}></input>
                  <input placeholder='Message' name='message' value={state.message} onChange={onFormChange}></input>
                  <button onClick={submitForm}>Submit</button>
               </div>
               <div className="cs-contact-col" >
                  <div className='cs-contact-info'>
                     <h2>CONTACT US</h2>
                     <p>Feel free to contact us with any of the below medium:</p>
                     <h3>Business Hour</h3>
                     <p>Our support Hotline(see the time and number below for availability)<br/>Monday-Friday : 9am to 6pm, – Saturday : 10am to 2pm</p>
                     <h3>Building Address</h3>
                     <p>Plot No.6, First Floor, Customs Colony<br/>Pudur, Vandipathai Road, Athikulam<br/>Madurai, Tamil Nadu, India 625014</p>
                     <h3 className='no-margin'>E-mail</h3>
                     <h3 className='no-margin'>admin[@]cloudsquads.in</h3>
                     <h3 className='no-margin'>Cell Phone</h3>
                     <h3 className='no-margin'>+91-452-4383525</h3>
                  </div>
               </div>
             </div>
          </div> 
       ); 
 }
 export default Contact;