import React, { useRef, useEffect } from 'react';
import './App.css';
import About from './components/about';
import Contact from './components/contact';
import Projects from './components/projects';
import OurServices from './components/our_services';
import { gsap } from "gsap";
import Header from './components/header';
import Home from './components/home';
import Footer from './components/footer';

function App() {
  const mainContentRef = useRef(null);
  useEffect(() => {
    gsap.from(mainContentRef.current, {
      autoAlpha: 0,
      ease: 'none',
      delay: 0.2
    });
  }, []);


  return (
    <div className='cs-main'>
      <Header>
      </Header>
      <div className='cs-body'>
        <div className='cs-content' ref={mainContentRef}>
          <Home>HOME CONTENT</Home>
          <OurServices></OurServices>
          <About></About>
          <Contact></Contact>
          <Footer></Footer>
        </div>
      </div>
    </div>
  );
}

export default App;
