import React from 'react';
import ReactDOM from 'react-dom';
import './about.scss';
import config from '../../config.json';
function About() {  
       return ( 
          <div id="csAbout" className='cs-about'>
             <div className="cs-about-txt">
               <h3>{config.about.why_us.title}</h3>
               <p>{config.about.why_us.content}</p>
             </div>
             <div className="cs-about-txt">
               <h3>{config.about.who.title}</h3>
               <p>{config.about.who.content}</p>
             </div>
          </div> 
       ); 
    }
 export default About;