import React, { useRef, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import gsap from "gsap";
import './home.scss';
function Home() {
   const [state, setState] = useState({
      email: "",
      mailLink: "mailto:admin@cloudsquads.in?subject=Service Enquiry&body=Please contact through this email "
   })
   const bgRef = useRef(null);

   useEffect(() => {
      gsap.from(bgRef.current, {
         autoAlpha: 0,
         ease: 'none',
         delay: 0.5
      });
   }, []);

   const handleEmailChange = event => {
      const value = event.target.value;
      let obj = Object.assign({}, state);
      obj.email = value;
      setState(obj);
   }

   return (
      <div id="csHome" ref={bgRef}>
         <div className="cs-home-img"></div>
         <div className="cs-home-content">
            <h1>Let's build the digital future</h1>
            <h4>Elevate your business by our digital solutions</h4>
            <input placeholder='Enter your email address' name='email' value={state.email} onChange={handleEmailChange}></input>
            <div className="cs-btn"><a href={state.mailLink + state.email + "."} rel="noopener noreferrer">Let's talk</a></div>
         </div>
      </div>
   );
}
export default Home;