import React from 'react';
import ReactDOM from 'react-dom';
import './footer.scss';
function Footer() {
   return (
      <div className='cs-footer'>
         <div className='cs-frow2'>
            <div className='cs-footer-logo'></div>
            <p className='cs-footer-color'>Follow us on</p>
            <div className='cs-social-icons'>
               <div className='icon-fb'></div>
               <div className='icon-twitter'></div>
               <div className='icon-in'></div>
               <div className='icon-instagram'></div>
               <div className='icon-youtube'></div>
            </div>
            <p className='cs-footer-color'>cloudsqauds.in</p>
         </div>
         <div className='cs-frow3 cs-footer-color'>
            <div>@2022 All rights reserved </div>
            <br></br>
            <div className='cs-margin-left'>Privacy Policy</div>
            <div>Terms of Service</div>
         </div>
      </div>
   );
}
export default Footer;