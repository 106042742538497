import React, { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import gsap from "gsap";
import { Link } from 'react-router-dom';
import './header.scss';


function Header() {
  const headerRef = useRef(null);
  const counter_ref = React.useRef(0);
  const [count, setCount] = React.useState(null);


  useEffect(() => {
    gsap.from(headerRef.current, {
      autoAlpha: 0,
      ease: 'none',
      delay: 0.2
    });
  }, []);

  function onclick() {
    counter_ref.current = counter_ref.current + 1;
    if (counter_ref.current % 2 !== 0) {
      gsap.to(".lines", { rotation: 90, duration: 0.5 });
      gsap.fromTo(".cs-menu2", { opacity: 0, x: 100 }, { opacity: 1, x: 0, duration: 0.5 });
    }
    else {
      gsap.to(".lines", { rotation: 0, duration: 0.5 });
      gsap.fromTo(".cs-menu2", { opacity: 1, x: 0 }, { opacity: 0, x: 100, duration: 0.5 });
    }
  }


  return (
    <div className="cs-header" ref={headerRef}
    >
      <div className='cs-logo' ></div>
      <ul className='cs-menu'>
        <li>
          <a href="#csHome" >HOME</a>
        </li>
        <li>
          <a href="#csAbout">ABOUT</a>
        </li>
        <li>
          <a href="#csHome">PROJECTS</a>
        </li>
        <li>
          <a href="#csServices">SERVICES</a>
        </li>
        <li>
          <a href="#csContact">CONTACT</a>
        </li>
      </ul>

      <ul className='cs-menu2'
      >
        <li>
          <a href="#csHome" >HOME</a>
        </li>
        <li>
          <a href="#csAbout">ABOUT</a>
        </li>
        <li>
          <a href="#csHome">PROJECTS</a>
        </li>
        <li>
          <a href="#csServices">SERVICES</a>
        </li>
        <li>
          <a href="#csContact">CONTACT</a>
        </li>
        <li>
          <br></br>
        </li>
        <li>
          <br></br>
        </li>
        <li>
          <br></br>
        </li>
        <li>
          <br></br>
        </li>
        <li>
          <br></br>
        </li>


      </ul>


      <div className='lines'
        onClick={() => onclick()}
      >
        <div className="menu-icon"></div>

      </div>

    </div >
  );
}
export default Header;